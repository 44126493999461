import { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import Button from '../../components/Button';
import './styles.css';
import Swal from 'sweetalert2';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9"
};

function Estrela(props) {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  //const [selecao, setSelecao] = useState(props.selecao);
  const stars = Array(5).fill(0);
  const [bloquear, setbloquear] = useState('Tcontainer');
  const [observacaoValue, setObservacaoValue] = useState(() => { if (props.executado !== undefined) { setbloquear('Tcontainer2') } });
  const [resolvidat, setResolvidat] = useState();
  const [executado, setExecutado] = useState(props.obj.tpp_executed || undefined);

  const [idTask, setIdtask] = useState('');

  const [opcoes, setOpcoes] = useState(
    executado !== undefined ?
      (executado === 1 ?
        <span> <input className="input" type="RADIO" name="OPCAO" checked disabled /> SIM
          <input className="input" type="RADIO" name="OPCAO" disabled /> NÃO</span> :
        <span> <input className="input" type="RADIO" name="OPCAO" disabled /> SIM
          <input className="input" type="RADIO" name="OPCAO" checked disabled /> NÃO</span>)
      :
      <span > <input className="input" type="radio" name="OPCAO" value="1" onChange={(e) => { setResolvidat(e.target.value) }} /> SIM
        <input className="input" type="radio" name="OPCAO" value="0" onChange={(e) => { setResolvidat(e.target.value) }} /> NÃO</span>
  );


  useEffect(() => {

    setIdtask(props.obj.tsk_id)

    if(props.obj.tpp_datetimeinsert !== null){

      setbloquear('Tcontainer2');
      setExecutado(props.obj.tpp_executed)  
      setObservacaoValue(props.obj.tpp_observation)
      setCurrentValue(props.obj.tpp_classification)
    }

  }, []);





  const handleClick = value => {
    setCurrentValue(value)
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }


  async function salvarClass() {

    console.log(resolvidat)
    if (resolvidat === undefined) {

      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: "Selecione SIM ou NÃO para concluir.",
        confirmButtonColor: "#222d32",
      })

    
    } else
      if (resolvidat == 1 && currentValue == 0) {

              
      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: "Selecione o que você achou do atendimento.",
        confirmButtonColor: "#222d32",
      })

      }
      else {


        const dados = [{
          tpp_executed: resolvidat,
          tpp_classification: currentValue,
          tpp_observation: observacaoValue,
          tsk_id: idTask,
        }]


        const _user = secureLocalStorage.getItem('t') 
        const endpoint = secureLocalStorage.getItem('ep') 


        axios({
          method: "post",
          url: endpoint + '/task/inserttaskapproval',
          headers: {
            Authorization: _user
          },
          data: {
            data: dados,
          }
        }).then(async function (ret) {


          setbloquear('Tcontainer2');

          Swal.fire({
            icon: "success",
            title: 'ATENÇÃO',
            text: `Classificação realizada com sucesso.`,
            confirmButtonColor: "#222d32",
          })

          props.updateTask();

        })
          .catch(function (error) {

            Swal.fire({
              icon: 'error',
              title: 'ATENÇÃO',
              text: `Não foi possível concluir o chamado.`,
              confirmButtonColor: "#222d32",
            })

          });



      }
  }

  return (

    <div className={bloquear} >
      <div className="selecao">
        <h2 className='solicitacao'>A solicitação foi resolvida?</h2>
        {opcoes}

      </div>
      <h2 className='solicitacao'> O que você achou do nosso Atendimento?</h2>
      <div style={styles.stars}>
        {stars.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
              color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>
      <textarea
        style={styles.textarea} placeholder="Deixar Seu Comentário"
        onChange={(e) => setObservacaoValue(e.target.value)} value={observacaoValue}>
       
      </textarea>

      <Button style={styles.button} type="button" onClick={salvarClass}>ENVIAR</Button>
    </div>

  );
};

const styles = {


  stars: {
    display: "flex",
    flexDirection: "row",
  },

  Pteste: {
    color: "#a9a9a9",
  },

  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 60,
    width: 300,
  },



  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  }

};


export default Estrela;
