import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes';
import {AuthProvider} from './context/index'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <AuthProvider>
            <Router/>
        </AuthProvider>
  </React.StrictMode>
);
