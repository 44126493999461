import React, { useMemo } from 'react';
import { Container }  from './styles';

interface IWalletBoxProps {
    title: string;
    icon: 'dolar' | 'arrowUp' | 'arrowDown';
    color: string;
    icone: string;
    valor: string;
}

const WalletBox: React.FC<IWalletBoxProps> = ({
    title,
    color,
    icone,
    valor,
   
}) => {
    return (
        <Container color={color}>        
            <span>{title}</span>
            <h1>
                {valor}
            </h1>
            <i className={icone}></i>
        </Container>
    );
}

export default WalletBox;