import "./index.css";
import React, { useState, useEffect } from 'react';
import nowT from '../../assets/images/MOB3.png';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import Card from '../../components/Card';
import { HeaderContainer, HeaderWrapper, UserInfo, Containertab, DashboardBackground, ModalAlinham, BodyContainer, Divbut, InlineContainer2, InlineContainer, Bot, HistoryChamados, DivTitulo, LoginInput, ButtonContainer } from './styles';
import Select from 'react-select'
import Button from '../../components/Button';
import WalletBox from '../../components/WalletBox';

const Dash = () => {

    const navigate = useNavigate();
    const [optionsLocal, setOptionsLocal] =
        useState([{
            value: 'TÉRREO - APARTAMENTO 001 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 001 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 002 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 002 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 003 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 003 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 004 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 004 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 005 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 005 - BLOCO LISBOA'
        },])
    const [optionsLoc, setOptionsLoc] = useState([])
    const [optionsPrioridade, setOptionsPrioridade] = useState([{ value: 'Alta', label: 'Alta' }, { value: 'Media', label: 'Media' }, { value: 'Baixa', label: 'Baixa' }])
    const [optionsContrato, setOptionsContrato] = useState([{ value: 'GRUPO UTT', label: 'GRUPO UTT' }])
    const [optionsUnidade, setOptionsUnidade] =
        useState([{ value: 'SEDE RECIFE', label: 'SEDE RECIFE' }])
    const [optionsPavimento, setOptionsPavimento] = useState([
        { value: 'TERREO', label: 'TÉRREO' },
        { value: '1º ANDAR', label: '1º ANDAR' },
        { value: '2º ANDAR', label: '2º ANDAR' },
        { value: '3º ANDAR', label: '3º ANDAR' },
    ])
    const [optionsAtivo, setOptionsAtivo] = useState([
        { value: 'SMART TV 43" UHD 4K SAMSUNG 43CU7700', label: 'SMART TV 43" UHD 4K SAMSUNG 43CU7700' },
        { value: 'TELEFONE FIXO INTELBRAS TC 60 ID PRETO', label: 'TELEFONE FIXO INTELBRAS TC 60 ID PRETO' },
        { value: 'AR-CONDICIONADO LG DUAL INVERTER', label: 'AR-CONDICIONADO LG DUAL INVERTER' },
        { value: 'ABAJUR DECORAÇÃO CONFORTO VISUAL LEITURA MINIMALISTA QUARTO', label: 'ABAJUR DECORAÇÃO CONFORTO VISUAL LEITURA MINIMALISTA QUARTO' },
        { value: 'CAMA BOX KING SIZE PROBEL FORT COMFORT', label: 'CAMA BOX KING SIZE PROBEL FORT COMFORT' },
        { value: 'FRIGOBAR BRASTEMP CLASSIC BLACK', label: 'FRIGOBAR BRASTEMP CLASSIC BLACK' },
        { value: 'CAFETEIRA ELÉTRICA ELECTROLUX EFFICIENT ECM10', label: 'CAFETEIRA ELÉTRICA ELECTROLUX EFFICIENT ECM10' },
    ])
    const [prioridade, setPrioridade] = useState('');
    const [observacao, setObservacao] = useState('');
    const [local, setLocal] = useState('');
    const [contrato, setContrato] = useState('');
    const [unidade, setUnidade] = useState('');
    const [pavimento, setPavimento] = useState('');
    const [ativo, setAtivo] = useState('');


    useEffect(() => {
        if(secureLocalStorage.getItem('ID') === 'MB0002'){
            setPrioridade('');
            setObservacao('');
            setLocal('TÉRREO - APARTAMENTO 001 - BLOCO LISBOA');
            setContrato('GRUPO UTT');
            setUnidade('SEDE RECIFE');
            setPavimento('TERREO');
            setAtivo('SMART TV 43" UHD 4K SAMSUNG 43CU7700');
        }else if(secureLocalStorage.getItem('ID') === 'MB0001'){
            setPrioridade('');
            setObservacao('');
            setLocal('TÉRREO - APARTAMENTO 001 - BLOCO LISBOA');
            setContrato('GRUPO UTT');
            setUnidade('SEDE RECIFE');
            setPavimento('TERREO');
        }

    }, []);

    const handleLogoff = () => {

        navigate('/login');
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')


    };

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '35px',
            textAlign: 'left',
            borderColor: state.isFocused ? 'grey' : '#92989e',
            fontWeight: 'bold',
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            padding: '0 6px',

        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',

        }),
    };


    function chamarHistorico(){
        navigate('/Historico');
    }

    return (
        <div className="Dashboard">
            <div className="HeaderContainer">
                <div className="HeaderWrapper">
                    <img src={nowT} width={172} height={61} alt="now" />
                    <div className="UserInfo">
                        <div className='username'>
                            <p>Olá, <span className="primary-color font-bold">Lucas</span></p>
                            <p className='usernameSair' onClick={handleLogoff}>Sair</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">

            <div className='content-principal-dash'>

<div className="container-ini-dashb">
    <div id="content1">
        <WalletBox
            title="Total de Chamados"
            color="#8D73D8"
            icon="arrowUp"
            icone="fa-solid fa-user-large"
            valor={'1'}
        />
    </div>
    <div id="content2">
        <WalletBox
            title="Concluídos"
            color="#8D73D8"
            icon="arrowUp"
            icone="fa-solid fa-calendar"
            valor={'2'}
        />
    </div>
    <div id="content3">
        <WalletBox
            title="Pendentes"
            color="#8D73D8"
            icon="arrowUp"
            icone="fa-solid fa-ticket"
            valor={'2'}
        />
    </div>
    <div id="content4">
        <WalletBox
            title="Em andamento"
            color="#8D73D8"
            icon="arrowUp"
            icone="fa-solid fa-thumbs-up"
            valor={'3'}
        />
    </div>
    </div>
</div>

            </div>
            <footer className="footer">
                <div className="footer-content">
                    <div className="container-cad-prod1">
                        <div id="content1">
                            <div className="container-cad-prod1-btn">

                                <div className="btn-container">
                                    <span className='fa fa-plus-circle icon-btn' />
                                    <h1 className="title-btn">Chamado</h1>
                                </div>

                            </div>

                        </div>
                        <div id="content2">
                            <div className="container-cad-prod1-btn" onClick={chamarHistorico}>

                                <div className="btn-container">
                                    <span className='fa fa-file-text icon-btn' />
                                    <h1 className="title-btn">Histórico</h1>
                                </div>

                            </div>
                        </div>
                        <div id="content3">
                            <div className="container-cad-prod1-btn">

                                <div className="btn-container">
                                    <span className='fa fa-area-chart icon-btn' />
                                    <h1 className="title-btn">Dashboard</h1>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Dash;
