import {CardContainer} from './styles';


interface CardProps {
    width?: string,
    children?: React.ReactNode;
    height?: string,
}

const Card = ({
    children,
    width='100%',
    height='auto'
    }: CardProps) => {
    return (
        <CardContainer width={width} height={height}>
            {children}
        </CardContainer>
    )
}

export default Card;
