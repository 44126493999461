import "./index.css";
import React, { useState, useEffect } from 'react';
import nowT from '../../assets/images/MOB3.png';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';
import Card from '../../components/Card';
import { HeaderContainer, HeaderWrapper, UserInfo, Containertab, DashboardBackground, ModalAlinham, BodyContainer, Divbut, InlineContainer2, InlineContainer, Bot, HistoryChamados, DivTitulo, LoginInput, ButtonContainer } from './styles';
import Select from 'react-select'
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import HistoryFinanceCard from '../../components/HistoryFinanceCard';


const Historico = () => {

    const navigate = useNavigate();
    const [filtro, setFiltro] = useState(99);
    const [optionsLocal, setOptionsLocal] =
        useState([{
            value: 'TÉRREO - APARTAMENTO 001 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 001 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 002 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 002 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 003 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 003 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 004 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 004 - BLOCO LISBOA'
        },
        {
            value: 'TÉRREO - APARTAMENTO 005 - BLOCO LISBOA',
            label: 'TÉRREO - APARTAMENTO 005 - BLOCO LISBOA'
        },])
    const [optionsLoc, setOptionsLoc] = useState([])
    const [optionsPrioridade, setOptionsPrioridade] = useState([{ value: 'Alta', label: 'Alta' }, { value: 'Media', label: 'Media' }, { value: 'Baixa', label: 'Baixa' }])
    const [optionsContrato, setOptionsContrato] = useState([{ value: 'GRUPO UTT', label: 'GRUPO UTT' }])
    const [optionsUnidade, setOptionsUnidade] =
        useState([{ value: 'SEDE RECIFE', label: 'SEDE RECIFE' }])
    const [optionsPavimento, setOptionsPavimento] = useState([
        { value: 'TERREO', label: 'TÉRREO' },
        { value: '1º ANDAR', label: '1º ANDAR' },
        { value: '2º ANDAR', label: '2º ANDAR' },
        { value: '3º ANDAR', label: '3º ANDAR' },
    ])
    const [optionsAtivo, setOptionsAtivo] = useState([
        { value: 'SMART TV 43" UHD 4K SAMSUNG 43CU7700', label: 'SMART TV 43" UHD 4K SAMSUNG 43CU7700' },
        { value: 'TELEFONE FIXO INTELBRAS TC 60 ID PRETO', label: 'TELEFONE FIXO INTELBRAS TC 60 ID PRETO' },
        { value: 'AR-CONDICIONADO LG DUAL INVERTER', label: 'AR-CONDICIONADO LG DUAL INVERTER' },
        { value: 'ABAJUR DECORAÇÃO CONFORTO VISUAL LEITURA MINIMALISTA QUARTO', label: 'ABAJUR DECORAÇÃO CONFORTO VISUAL LEITURA MINIMALISTA QUARTO' },
        { value: 'CAMA BOX KING SIZE PROBEL FORT COMFORT', label: 'CAMA BOX KING SIZE PROBEL FORT COMFORT' },
        { value: 'FRIGOBAR BRASTEMP CLASSIC BLACK', label: 'FRIGOBAR BRASTEMP CLASSIC BLACK' },
        { value: 'CAFETEIRA ELÉTRICA ELECTROLUX EFFICIENT ECM10', label: 'CAFETEIRA ELÉTRICA ELECTROLUX EFFICIENT ECM10' },
    ])
    const [prioridade, setPrioridade] = useState('');
    const [observacao, setObservacao] = useState('');
    const [local, setLocal] = useState('');
    const [contrato, setContrato] = useState('');
    const [unidade, setUnidade] = useState('');
    const [pavimento, setPavimento] = useState('');
    const [ativo, setAtivo] = useState('');
    const [selection, setSelection] = useState({ 20: "", 30: "", 40: "", 50: "", 99: "" });
    const [dataIni, setDataini] = useState('');
    const [datafim, setDatafim] = useState('');
    const [task, setTask] = useState([]);
    const [tasks, setTasks] = useState([])
    const [tas, seTtas]= useState([
        {
            tsk_id: 13019,
            tsk_integrationid: "",
            age_id: null,
            loc_id: 440,
            tsk_scheduleinitialdatehour: "2024-07-05T10:50:00.000Z",
            tsk_realinitialdatehour: null,
            tsk_schedulefinaldatehour: null,
            tsk_realfinaldatehour: null,
            tsk_observation: "Descarga se ecnontra com vazamento.",
            tsk_active: 1,
            stn_id: 50,
            tsk_waitingtime: null,
            tsk_priority: null,
            src_id: 5,
            tsf_id: 1,
            tea_id: 38,
            tsk_activityorigin: null,
            tsk_mobilesenddate: null,
            tsk_accesstoken: null,
            age_id_insert: 30,
            tsk_datetimeinsert: "2024-07-05T13:50:53.000Z",
            age_id_lastupdate: null,
            tsk_datetimeupdate: "2024-07-05T13:50:53.000Z",
            tty_id: 51,
            age_id_mobile: null,
           tsk_notification: 0,
            tsk_recreate: 0,
            tcr_id: null,
            sgp_id: null,
            tsk_requester: "teste",
            tsk_responsible: "teste",
            pla_id: null,
            tsk_technicalinstruction: null,
            fqc_id: null,
            tsk_callback: null,
            tsk_telephone: "21123",
            tsk_agendadescription: null,
            tsk_image1: null,
            tsk_image2: null,
            tsk_origemid: null,
            tsk_idint: null,
            tsk_pendingshipment: null,
            loc_description: "TÉRREO - APARTAMENTO 001 - BLOCO LISBOA",
            stn_description: "Em Campo",
            tpp_classification: null,
            tpp_observation: null,
            tpp_executed: null,
            tpp_datetimeinsert: null
        },
        {
            tsk_id: 13019,
            tsk_integrationid: "",
            age_id: null,
            loc_id: 440,
            tsk_scheduleinitialdatehour: "2024-07-05T10:50:00.000Z",
            tsk_realinitialdatehour: null,
            tsk_schedulefinaldatehour: null,
            tsk_realfinaldatehour: null,
            tsk_observation: "teste",
            tsk_active: 1,
            stn_id: 50,
            tsk_waitingtime: null,
            tsk_priority: null,
            src_id: 5,
            tsf_id: 1,
            tea_id: 38,
            tsk_activityorigin: null,
            tsk_mobilesenddate: null,
            tsk_accesstoken: null,
            age_id_insert: 30,
            tsk_datetimeinsert: "2024-07-05T13:50:53.000Z",
            age_id_lastupdate: null,
            tsk_datetimeupdate: "2024-07-05T13:50:53.000Z",
            tty_id: 51,
            age_id_mobile: null,
           tsk_notification: 0,
            tsk_recreate: 0,
            tcr_id: null,
            sgp_id: null,
            tsk_requester: "teste",
            tsk_responsible: "teste",
            pla_id: null,
            tsk_technicalinstruction: null,
            fqc_id: null,
            tsk_callback: null,
            tsk_telephone: "21123",
            tsk_agendadescription: null,
            tsk_image1: null,
            tsk_image2: null,
            tsk_origemid: null,
            tsk_idint: null,
            tsk_pendingshipment: null,
            loc_description: "TÉRREO - APARTAMENTO 002 - BLOCO LISBOA",
            stn_description: "Em Campo",
            tpp_classification: null,
            tpp_observation: null,
            tpp_executed: null,
            tpp_datetimeinsert: null
        },
        {
            tsk_id: 13019,
            tsk_integrationid: "",
            age_id: null,
            loc_id: 440,
            tsk_scheduleinitialdatehour: "2024-07-05T10:50:00.000Z",
            tsk_realinitialdatehour: null,
            tsk_schedulefinaldatehour: null,
            tsk_realfinaldatehour: null,
            tsk_observation: "teste",
            tsk_active: 1,
            stn_id: 40,
            tsk_waitingtime: null,
            tsk_priority: null,
            src_id: 5,
            tsf_id: 1,
            tea_id: 38,
            tsk_activityorigin: null,
            tsk_mobilesenddate: null,
            tsk_accesstoken: null,
            age_id_insert: 30,
            tsk_datetimeinsert: "2024-07-05T13:50:53.000Z",
            age_id_lastupdate: null,
            tsk_datetimeupdate: "2024-07-05T13:50:53.000Z",
            tty_id: 51,
            age_id_mobile: null,
           tsk_notification: 0,
            tsk_recreate: 0,
            tcr_id: null,
            sgp_id: null,
            tsk_requester: "teste",
            tsk_responsible: "teste",
            pla_id: null,
            tsk_technicalinstruction: null,
            fqc_id: null,
            tsk_callback: null,
            tsk_telephone: "21123",
            tsk_agendadescription: null,
            tsk_image1: null,
            tsk_image2: null,
            tsk_origemid: null,
            tsk_idint: null,
            tsk_pendingshipment: null,
            loc_description: "TÉRREO - APARTAMENTO 003 - BLOCO LISBOA",
            stn_description: "Em Campo",
            tpp_classification: null,
            tpp_observation: null,
            tpp_executed: null,
            tpp_datetimeinsert: null
        },
        {
            tsk_id: 13019,
            tsk_integrationid: "",
            age_id: null,
            loc_id: 440,
            tsk_scheduleinitialdatehour: "2024-07-05T10:50:00.000Z",
            tsk_realinitialdatehour: null,
            tsk_schedulefinaldatehour: null,
            tsk_realfinaldatehour: null,
            tsk_observation: "teste",
            tsk_active: 1,
            stn_id: 30,
            tsk_waitingtime: null,
            tsk_priority: null,
            src_id: 5,
            tsf_id: 1,
            tea_id: 38,
            tsk_activityorigin: null,
            tsk_mobilesenddate: null,
            tsk_accesstoken: null,
            age_id_insert: 30,
            tsk_datetimeinsert: "2024-07-05T13:50:53.000Z",
            age_id_lastupdate: null,
            tsk_datetimeupdate: "2024-07-05T13:50:53.000Z",
            tty_id: 51,
            age_id_mobile: null,
           tsk_notification: 0,
            tsk_recreate: 0,
            tcr_id: null,
            sgp_id: null,
            tsk_requester: "teste",
            tsk_responsible: "teste",
            pla_id: null,
            tsk_technicalinstruction: null,
            fqc_id: null,
            tsk_callback: null,
            tsk_telephone: "21123",
            tsk_agendadescription: null,
            tsk_image1: null,
            tsk_image2: null,
            tsk_origemid: null,
            tsk_idint: null,
            tsk_pendingshipment: null,
            loc_description: "TÉRREO - APARTAMENTO 004 - BLOCO LISBOA",
            stn_description: "Em Campo",
            tpp_classification: null,
            tpp_observation: null,
            tpp_executed: null,
            tpp_datetimeinsert: null
        }
    ])


    useEffect(() => {
        getTask(tas)
    }, []);

    async function getTask(data: any) {

        let chamados: any;

        setSelection({ 20: "", 30: "", 40: "", 50: "", 99: "2px dotted #000" })

        setTask(<Loading /> as any);

        const endpoint = secureLocalStorage.getItem('ep') as ''

 
                    chamados = await reactObj(data);

                
                    setTasks(data);
                   setTask(chamados);
                    // setQuantNot(chamados.filter((value: any) => (value.props.obj.classificacao === undefined && value.props.obj.situation.id === "50")).length);
                    

    }

    function reactObj(objeto: any) {

        const chamados = objeto.map((prod: any, index: any) => {
            return <HistoryFinanceCard
                key={index}
                obj={prod}
                updateTask={getTask}
            />
        })
        return chamados;

    }

    const handleLogoff = () => {

        navigate('/login');
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')


    };

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '35px',
            textAlign: 'left',
            borderColor: state.isFocused ? 'grey' : '#92989e',
            fontWeight: 'bold',
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',
            padding: '0 6px',

        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '35px',

        }),
    };


    function chamardashboard(){
        navigate('/dashboard');
    }

    function filtrar(id: number) {
        setFiltro(id);

        const newSelection = { 20: "", 30: "", 40: "", 50: "", 99: "" }
        setSelection(selection => (
            {
                ...newSelection,
                [id]: "2px dotted #000"
            }
        ))

        let tsk: any

        if (tasks !== null) {

            if (id !== 99) {

                const newTask = tasks.filter((value: any) => {
                    return value.stn_id == id ? true : false;

                })


                if (newTask.length > 0) {
                    tsk = reactObj(newTask)
                    setTask(tsk);
                } else {
                    setTask(<div></div> as any)
                }


            } else {
                tsk = reactObj(tasks)
                setTask(tsk);
            }
        }
     
    }

    return (
        <div className="Dashboard">
            <div className="HeaderContainer">
                <div className="HeaderWrapper">
                    <img src={nowT} width={172} height={61} alt="now" />
                    <div className="UserInfo">
                        <div className='username'>
                            <p>Olá, <span className="primary-color font-bold">Lucas</span></p>
                            <p className='usernameSair' onClick={handleLogoff}>Sair</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">

                <Card noShadow width="90%">
                    <h2 className="h2">Histórico</h2>

                    <Bot>

                        <button className="button button5" style={{ border: selection[20] }} onClick={() => { filtrar(20) }}>Em analise</button>
                        <button className="button button4" style={{ border: selection[30] }} onClick={() => { filtrar(30) }}>Aguardando</button>
                        <button className="button button3" style={{ border: selection[40] }} onClick={() => { filtrar(40) }}>Em campo</button>
                        <button className="button button2" style={{ border: selection[50] }} onClick={() => { filtrar(50) }}>Encerrada</button>
                        <button className="button button6" style={{ border: selection[99] }} onClick={() => { filtrar(99) }}>Todos</button>
                    </Bot>
                 
                    <Divbut>
                        <div className="container-1">
                            <div id="content1">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idini"

                                    onChange={(e) => setDataini(e.target.value)}
                                    value={dataIni}
                                />

                            </div>
                            <div id="content2">


                                <input
                                    type="date"
                                    className='input-data'
                                    id="idfim"
                                    onChange={(e) => setDatafim(e.target.value)}
                                    value={datafim}
                                />

                            </div>
                            <div id="content3">
                                
                                <h1 className="h1-busca">Pesquisar</h1>
                                
                            </div>

                        </div>
                    </Divbut>
                    <HistoryChamados>
                        {task}
                    </HistoryChamados>
                </Card>

            </div>
            <footer className="footer">
                <div className="footer-content">
                    <div className="container-cad-prod1">
                        <div id="content1">
                            <div className="container-cad-prod1-btn" onClick={chamardashboard}>

                                <div className="btn-container">
                                    <span className='fa fa-plus-circle icon-btn' />
                                    <h1 className="title-btn">Chamado</h1>
                                </div>

                            </div>

                        </div>
                        <div id="content2">
                            <div className="container-cad-prod1-btn">

                                <div className="btn-container">
                                    <span className='fa fa-file-text icon-btn' />
                                    <h1 className="title-btn">Histórico</h1>
                                </div>

                            </div>
                        </div>
                      
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Historico;
