import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import Dashboard from "../pages/Dashboard"
import Historico from "../pages/Historico"
import Dash from '../pages/Dash'
import Login from "../pages/SignIn";
import NoPage from "../pages/NotFound"


import ProtectedRoutes from "./ProtectedRoutes"
import PublicRoutes from "./PublicRoutes"
import PermissionDenied from "./PermissionDenied"
import InnerContent from "./InnerContent"


export function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>

            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="Historico" element={<Historico />} />
            <Route path="Dash" element={<Dash />} />

          </Route>

        </Route>


        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login/:id" element={<Login />} />
        </Route>
        <Route path="chamados" element={<PublicRoutes />}>
          <Route path="/chamados/:entidade/:id" element={<Login />} />
        </Route>

        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<NoPage />} />
      </Routes>


    </BrowserRouter>
  )
}

export default Router
