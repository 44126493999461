import styled, {css} from 'styled-components';


export const CardContainer = styled.div<{
    width: string;
    height: string;
}>`
    width: '100%';
    height: 600px;

    background: #fff;
    
    border-radius: 10px;
    
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    //padding: 25px;
    display:flex;
    align-items: center;
    flex-direction: column;
    border: 0px;
`