import styled, {keyframes} from 'styled-components';

interface IContainerProps {
    color: string;
}

const animate = keyframes`
    0%{
        transform: translateX(100px);
        opacity: 0;
    }
    50%{     
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div<IContainerProps>`
    background-color: #fff;
    width: 100%;
    height:105px;
    margin: 12px 0;
    border-top: 3px solid ${props => props.color};
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 15px 15px;
    position: relative;
    overflow: hidden;
    animation: ${animate} .5s; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    > i {
        position: absolute;
        font-size: 50px;
        right: 10px;
        top: 45px;
    }
    .test{
        color:red;
    }

    > span {
        font-size: 16px;
        font-weight: 500;
        font-weight: bold;
        color: #455560
    }

    > small {
        font-size: 12px;
        position: absolute;
        bottom: 10px; 
    }

    h1{
        color: #455560;
        background-color: #fff;
        position: absolute;
        text-align: left;
        margin-left: 40px;
        font-size: 30px;
        margin-top: 5px;
        
    }

   


    @media(max-width: 770px){
        width: 100%;

        > span {
            font-size: 14px;
        }

        > h1 {
            word-wrap: break-word;
            font-size: 22px;

            strong {
                display: inline-block;
                width: 100%;
                font-size: 16px;
            }
        }
    }

    @media(max-width: 420px){
        width: 100%;
       
        > h1 {
            display: flex;
            
            strong {
                position: initial;        
                width: auto;
                font-size: 22px;
            }

            strong:after {
                display: inline-block;
                content: '';
                width: 1px;                
            }
        }
    }
`;