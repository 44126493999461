import axios from 'axios';

const api = axios.create({
    baseURL: 'https://tudoapi.mobcode.com.br:3334/'

    //'https://apismart.adlim.com.br:3335/'
    //https://192.168.5.129:3335/
 
})

//https://apicentral.adlim.com.br:3337 amb_endpointweb

export default api;



