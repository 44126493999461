import { Container, Tag, Title, ContResumo } from './styles';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import ModalResumo from '../../components/ModalResumo/modal'
import Estrela from '../Estrela/index';
import React, { useState, useReducer, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import './index.css'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import Loading from '../Loading/index';
import { Link } from "react-router-dom";

import { FaClipboardList } from 'react-icons/fa';
interface IHistoryFinanceCardProps {
  obj: any;
  updateTask: any;
}

const HistoryFinanceCard: React.FC<IHistoryFinanceCardProps> = ({
  obj,
  updateTask
}) => {


  const [dateTask, setDateTask] = useState('');
  const [tagColor, setTagColor] = useState('');
  const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
  const [openModalIncluirNovo2, setOpenModalIncluirNovo2] = useState(false)
  const [titulo, setTitulo] = useState('RESUMO DO ATENDIMENTO');
  const [task, setTask] = useState(<></>);

  const [htv_dataini, setHtv_dataini] = useState('');
  const [htv_horaini, setHtv_horaini] = useState('');
  const [htv_colaborador, setHtv_colaborador] = useState('');
    const [ htv_qrcodeini, setHtv_qrcodeini] = useState('');
      const [htv_datafim, setHtv_datafim] = useState('');
        const [htv_horafim, setHtv_horafim] = useState('');
          const [ htv_qrcodefim, setHtv_qrcodefim] = useState('');
            const [htv_image1, setHtv_image1] = useState('');
              const [htv_image2, setHtv_image2] = useState('');

  useEffect(() => {

    
    const isoDate = obj.tsk_datetimeinsert;
    const timeZone = 'America/Sao_Paulo';

    // Converte a data de UTC para o fuso horário brasileiro
    const brazilianTime = moment.tz(isoDate, timeZone);

    // Formata a data de acordo com o formato desejado
    setDateTask(brazilianTime.format('DD/MM/YYYY HH:mm:ss'))


    if (obj.stn_id === 20) {
      setTagColor('#f44336')
    } else if (obj.stn_id === 30) {
      setTagColor('#ffa500')
    } else if (obj.stn_id === 40) {
      setTagColor('#008CBA')
    } else if (obj.stn_id === 50) {
      setTagColor('#4CAF50')
    }
    else if (obj.stn_id === 70) {
      setTagColor('#bbb9b9')
    }

    getTask()

  }, []);



  function fecharOpenModalIncluirNovo() {
    setOpenModalIncluirNovo(false)
  }

  function abriModal() {
    setOpenModalIncluirNovo(true)
    
    //setOpenModalIncluirNovo2(true)
    
  }


  async function getTask() {

    setTask(<Loading />);

    if(obj.stn_id === 50){
 
    const endpoint = secureLocalStorage.getItem('ep') as ''

    axios.get(endpoint + '/task/taskhistorico', {
        params: {
            id: 11242
        }
    })
        .then(async function (response:any) {

            if (response.status === 200) {
              
              const isoDate = response.data.data[0].htv_dataini;
    const timeZone = 'America/Sao_Paulo';

    // Converte a data de UTC para o fuso horário brasileiro
    const brazilianTime = moment.tz(isoDate, timeZone);

    // Formata a data de acordo com o formato desejado


          
              setHtv_dataini(brazilianTime.format('DD/MM/YYYY'))
              setHtv_horaini(response.data.data[0].htv_horaini)
              setHtv_colaborador(response.data.data[0].htv_colaborador);
              setHtv_qrcodeini(response.data.data[0].htv_qrcodeini);
              setHtv_datafim(brazilianTime.format('DD/MM/YYYY'));
              setHtv_horafim(response.data.data[0].htv_horafim);
              setHtv_qrcodefim(response.data.data[0].htv_qrcodefim);
              setHtv_image1(response.data.data[0].htv_image1);
              setHtv_image2(response.data.data[0].htv_image2);


            }else{
              setTask(<></>);
            }
        })
        .catch(async function (error:any) {

          setTask(<></>);

        });
      }else{
        setTask(<></>);
      }

}

function AbrirRelatorio(){
  setOpenModalIncluirNovo2(true)
}


function fecharRelatorio(){
  setOpenModalIncluirNovo2(false)
}

  return (
    <>
      <Container>
        <Tag color={tagColor} />
        <div>
          <Title>
            <span>OS: {obj.tsk_id} DATA: {dateTask}</span>
          </Title>

          <Title>
            <span>{obj.loc_description} </span>
          </Title>
        </div>
        <div>
          <FaClipboardList onClick={abriModal} />

        </div>

      </Container>

      <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
        <>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{titulo}</h1>
            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
          </div>

          <div className='container-modal-incluir'>

            <ContResumo>
              <div className="text">
                <span onClick={AbrirRelatorio}><b className="textid">ID: {obj.tsk_id} </b></span>
              </div>
              <div className="text">
                <span><b>STATUS: {obj.stn_description}</b> </span>
              </div>
              <div className="text">
                <span><b>LOCAL: {obj.loc_description}</b> </span>
              </div>
              <div className="text">
                <span><b>DATA DE ABERTURA: {dateTask}</b> </span>
              </div>
              <div className="text">
                <span><b>SOLICITANTE: {obj.tsk_requester}</b> </span>
              </div>
              <div className="text">
                <span><b>DESCRIÇÃO DO PROBLEMA: {obj.tsk_observation}</b> </span>
              </div>
            </ContResumo>

            
            {obj.stn_id === 50 ? <div>
              <Estrela
                obj={obj}
                updateTask={updateTask}
              />
            </div> : null}

         
          </div>
        </>
      </ModalIncluirNovo>


      <ModalResumo isOpen={openModalIncluirNovo2} setModalOpen={() => setOpenModalIncluirNovo2(!openModalIncluirNovo2)}>
        <div  className='title-modal-inclui-mod'>
          <div className='title-modal-incluir'>
            <h1 className="modal-IncluirNovo-title">{titulo}</h1>
            <button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharRelatorio} />
          </div>

          <div className='container-modal-incluir'>

            <ContResumo>
              <div className="text">
                <span><b>ID: {obj.tsk_id}   </b></span> 
              </div>
              <div className="text">
                <span><b>STATUS: {obj.stn_description}</b> </span>
              </div>
              <div className="text">
                <span><b>LOCAL: {obj.loc_description}</b> </span>
              </div>
              <div className="text">
                <span><b>DATA DE ABERTURA: {dateTask}</b> </span>
              </div>
              <div className="text">
                <span><b>SOLICITANTE: {obj.tsk_requester}</b> </span>
              </div>
              <div className="text">
                <span><b>DESCRIÇÃO DO PROBLEMA: {obj.tsk_observation}</b> </span>
              </div>

              <div className="text3">
                <span><b>Iniciar</b></span>
              </div>

              <div className="text">
                <span><b>Data Inicial: {htv_dataini}</b> </span>
              </div>

              <div className="text">
                <span><b>Hora Inicial: {htv_horaini}</b> </span>
              </div>

              <div className="text">
                <span><b>Colaborador: {htv_colaborador}</b> </span>
              </div>
              <div className="text">
                <span><b>Qrcode Inicial: {htv_qrcodeini}</b> </span>
              </div>

              <div className="text3">
                <span><b>Finalizar</b></span>
              </div>

              <div className="text">
                <span><b>Data Final: {htv_dataini}</b> </span>
              </div>

              <div className="text">
                <span><b>Hora Final: {htv_horaini}</b> </span>
              </div>

              <div className="text">
                <span><b>Colaborador: {htv_colaborador}</b> </span>
              </div>
              <div className="text">
                <span><b>Qrcode Final: {htv_qrcodeini}</b> </span>
              </div>

              <div className="text">
                <span><b>Fotos:</b> </span>
              </div>
    
              <div className="text-flex">
              <img 
          src="https://adlimnowimg.s3.amazonaws.com/adlimNowImg/fe8aacb8-13d4-4804-af8f-cc2d9f7a2e0e.jpg" 
          alt="Descrição da Imagem" 
          style={{ width: '300px', height: 'auto', marginTop: '10px'}} 
        />

        <img 
          src="https://adlimnowimg.s3.amazonaws.com/adlimNowImg/87862ef8-b907-42ad-b289-b04d970f0049.jpg" 
          alt="Descrição da Imagem" 
          style={{ width: '300px', height: 'auto', marginTop: '10px', marginLeft:'20px' }} 
        />
              </div>

              
            </ContResumo>
      
           

         
          </div>
          
        </div>
      </ModalResumo>

    </>
  );
}

export default HistoryFinanceCard;