import styled from 'styled-components';


export const CardContainer = styled.div<{
    width: string;
    height: string;
}>`
    width: ${(props: any) => props.width};
    height: ${(props: any) => props.height};
    background: #FFFFFF;
    box-shadow: 5px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 25px;
    display:flex;
    align-items: center;
    flex-direction: column;
   margin-left: 10px;
   margin-right: 10px

`