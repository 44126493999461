import React from 'react';
import './style.css'


const Loading = () => {
    return (
        <div className='loader'></div>
     )
}

export default Loading;