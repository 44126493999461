
import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import { useNavigate } from 'react-router';
import { MdEmail, MdLock } from "react-icons/md"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { FaBuilding } from "react-icons/fa"
import { AxiosResponse } from 'axios';
import logo from '../../assets/images/MOB.png'
import Swal from 'sweetalert2';
import api from '../../Services/api'
import { useAuth } from '../../context/useAuth';
import  secureLocalStorage  from  "react-secure-storage";
import axios from 'axios'; 
import ModalPrincipal from '../../components/ModalAlert/modal'
import { Wrapper, InputContainer, ButtonContainer, AlertaT } from './styles';
import Card from '../../components/CardLogin';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useParams } from 'react-router-dom'; 

function Login() {

    let { id, entidade } = useParams();
   const auth = useAuth()

   const [exibirGrid, setExibirGrid] = useState(<></>);
   const [openModal, setOpenModal] = useState(false)
   const [qualAlert, setQualAlert] = useState(<></>)

   const navigate = useNavigate();
   const [company, setCompany] = useState("TESTE")
   const [login, setLogin] = useState("")
   const [senha, setSenha] = useState("")
   const [show, setShow] = useState(false)
   const [sucesso, setSucesso] = useState('');
   const [sucessoID, setSucessoID] = useState('');

   useEffect(() => {

      localStorage.removeItem("PrivateRoute");
      setSucessoID(id || '')

      console.log(entidade, id)
   }, []);

   const handleClick = (e: any) => {
      e.preventDefault()
      setShow(!show);
   }


   async function ValidarLogin() {

      let ret: AxiosResponse;

      try {   ///https://apicentral.adlim.com.br:3334/ambientes/validar
          ret = await api.get('https://apicentral.adlim.com.br:3334/ambientes/validar', {
              params: {
                  ambiente: company,
              }
          })

          if (ret.data.status === 200) {


              secureLocalStorage.setItem('ep', ret.data.data[0].amb_endpointweb);
              secureLocalStorage.setItem('cp', company) 

              //https://apicentral.adlim.com.br:3337
              let endpoint = ret.data.data[0].amb_endpointweb

              axios({
                  method: "get",
                  url: endpoint+'/user/login',
                   params: {
                           company: company,
                           login: login,
                           senha: senha
                           },
                }).then(async function (response) {
         

                      secureLocalStorage.setItem('t', response.data.userToken);
                      secureLocalStorage.setItem('acp', response.data.data[0].acp_integrationid);
                      secureLocalStorage.setItem('n', response.data.data[0].age_name);
                      secureLocalStorage.setItem('ID', sucessoID);
                      
                      navigate('/dashboard');
                    
                  })
                  .catch(function (error) {
                     console.log(error)
                     
                      Swal.fire({
                          icon: 'error',
                          title: 'ATENÇÃO',
                          text: `Login inválido.`,
                          confirmButtonColor: "#222d32",
                      })
            
                  });

          }

      } catch (e: any) {



          Swal.fire({
              icon: 'error',
              title: 'ATENÇÃO',
              text: `Login inválido.`,
              confirmButtonColor: "#222d32",
          })

      }
   
      
   }

   function handleInput(e: any) {
      var ss = e.target.selectionStart;
      var se = e.target.selectionEnd;
      e.target.value = e.target.value.toUpperCase();
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
   }

   return (
      <Wrapper>

      <Card width="403px" height="auto">
          <img className='img-das' src={logo} width={300} height={100} alt="now" />

          <InputContainer>
              <Input placeholder="Digite aqui seu usuário" value={login}
                  onChange={e => setLogin(e.target.value)}
                />
              <Input placeholder="Digite aqui sua senha" type="password" value={senha}
                  onChange={e => setSenha(e.target.value)} />
          </InputContainer>

          {sucesso === 'N' ? <AlertaT><p>Usuário ou senha inválida.</p></AlertaT> : null}
          <ButtonContainer>
              <Button type="button" onClick={ValidarLogin}>ENTRAR</Button>

              {/* <p><Link to="/signup">Esqueceu sua senha?</Link></p> */}
          </ButtonContainer>

      </Card>


  </Wrapper>

   )
}

export default Login

