import { InputHTMLAttributes } from 'react';
import {InputContainer} from './styles';


const Input = (props: InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <InputContainer>
            <input {...props}  className='input-amb'/>
        </InputContainer>
    )
}

export default Input;